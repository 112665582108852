// Stat.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CountUp from 'react-countup';


const Stat = ({ userId, movement, scoreCategory, scoreUnit, dateFilter, operation, postType, posts, className, onLoading = () => {} }) => {
  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    filterPosts();
  }, [movement, scoreCategory, scoreUnit, dateFilter, postType, posts]); 

  useEffect(() => {
    if (posts.length > 0) {
      filterPosts();
    }
  }, [dateFilter]);

  const filterPosts = () => {
    const filtered = posts.filter((post) => {
      const now = new Date();
      const postDate = new Date(post.date);
      return (!movement || post.movement === movement) &&
             (scoreCategory === null || !scoreCategory || post.score_category === scoreCategory) &&
             (!scoreUnit || post.score_unit === scoreUnit) &&
             (!postType || post.type === postType) &&
             (!dateFilter || (now - postDate <= dateFilter * 24 * 60 * 60 * 1000));
    });
    setFilteredPosts(filtered);
  };

  const calculateStat = (operation) => {
    if (filteredPosts.length === 0) return 0;
    const field = scoreUnit === 'Lbs' ? 'score' : (postType && ['Workout', 'Sun', 'Sauna', 'Cold'].includes(postType) ? 'minutes' : 'score');

    let result;
    if (operation === 'sum') {
      result = filteredPosts.reduce((acc, post) => acc + post[field], 0);
    } else if (operation === 'average') {
      result = filteredPosts.reduce((acc, post) => acc + post[field], 0) / filteredPosts.length;
    }
    console.log('Result:', result);
    return result;
  };

  const displayResult = () => {
    let resultLabel = "";
    let resultValue = 0;
    let scoreUnitDisplay = "";

    if (operation === 'sum') {
      resultLabel = "Total";
      resultValue = calculateStat('sum');
    } else if (operation === 'average') {
      resultLabel = "Average";
      resultValue = calculateStat('average');
    }

    if (postType && ['Workout', 'Sun', 'Sauna', 'Cold'].includes(postType)) {
      scoreUnitDisplay = 'Mins';
    } else {
      scoreUnitDisplay = scoreUnit;
    }

    return { label: resultLabel, value: resultValue, unit: scoreUnitDisplay };

  };

  const result = displayResult();
  return (
    result.value !== 0 && (
      <div className={`${className} ${postType ? 'stat-type-' + postType.toLowerCase() : ''}`}>
        <div className="label">
          {movement && <span className="stat-movement">{movement}</span>}
          {scoreCategory && <span className="stat-category">{scoreCategory}</span>}
          {postType && <span className="stat-type">{postType}</span>}
        </div>
        <div className="number">
          {result.value !== undefined && (
            <CountUp
              start={0}
              end={result.value}
              duration={1}
              separator=","
              decimals={0} // Set decimals to 0 for integers without decimals
              className="stat-result-value"
            />
          )}
          {result.unit && <span className="stat-result-unit">{result.unit}</span>}
        </div>
        <div className="unit">
          {result.label && <span className="stat-result-label">{result.label}</span>}
        </div>
      </div>
    )
  );
};

export default Stat;
