import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavbarContext from './NavbarContext';

const Login = () => {
  const { updateNavbar } = useContext(NavbarContext);
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null); 
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/forgot-password`, {
        email: identifier,
      });
      setMessage(
        "Check your email for a password reset link. If you don't see one in a few minutes, please make sure the email you entered is the one you used to sign up for Fitpholio."
      ); 
    } catch (error) {
      setError('An error occurred during login. Please check your credentials and try again.');
    }
  };

  return (
    <div className="stage">
      <h1>Forgot Password</h1>
      <h2>Enter your email and we'll send you a link to reset your password.</h2>
      {error && <p>{error}</p>}
      {message && <p>{message}</p>} 
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={identifier}
          onChange={(event) => setIdentifier(event.target.value)}
          required
        />
        <button className="btn-primary" type="submit">Send</button>
      </form>
    </div>
  );
};

export default Login;
