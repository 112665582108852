import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getUrlPrefix } from './utils/utils';
import defaultProfilePic from './img/placeholder.svg';
import ReactMarkdown from 'react-markdown';

const Settings = () => {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const [editing, setEditing] = useState(false);
  const [userData, setUserData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [profilePic, setProfilePic] = useState(userData?.profile_pic || '');
  const [profilePicPreview, setProfilePicPreview] = useState(userData?.profile_pic || '');

  const uploadProfilePic = async (file) => {
    const formData = new FormData();
    formData.append('files', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${currentUser.jwt}`, // Add the JWT token to the Authorization header
        },
      });

      // Return the uploaded file's ID
      return response.data[0].id;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };


  useEffect(() => {
    const fetchUserData = async () => {
      await refreshUserData();
    };

    fetchUserData();
  }, [currentUser.user.id, editing]);

  const refreshUserData = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${currentUser.user.id}?populate=profile_pic`);
    setUserData(response.data);
    setProfilePicPreview(response.data.profile_pic?.url ? `${getUrlPrefix()}${response.data.profile_pic.url}` : '');

  };

  const handleProfilePicChange = (event) => {
    const file = event.target.files[0];
    setProfilePic(file);

    // Create a preview URL for the selected image
    const previewURL = URL.createObjectURL(file);
    setProfilePicPreview(previewURL);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('first_name', event.target.first_name.value);
    formData.append('last_name', event.target.last_name.value);
    formData.append('username', event.target.username.value);
    formData.append('email', event.target.email.value);
    formData.append('bio', event.target.bio.value);

    if (profilePic) {
      const uploadedFileId = await uploadProfilePic(profilePic);

      if (uploadedFileId) {
        formData.append('profile_pic', uploadedFileId);
      } else {
        setErrorMessage('Error uploading profile picture.');
        return;
      }
    }

    const usernameTaken = await checkForDuplicate('username', formData.get('username'));
    const emailTaken = await checkForDuplicate('email', formData.get('email'));

    if (
      usernameTaken &&
      formData.get('username').toLowerCase() !==
        currentUser.user.username.toLowerCase()
    ) {
      setErrorMessage('The username is already taken.');
      return;
    }

    if (
      emailTaken &&
      formData.get('email').toLowerCase() !== userData.email.toLowerCase()
    ) {
      setErrorMessage('The email is already taken.');
      return;
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/users/${userData.id}?populate=profile_pic`, formData, {
        headers: {
          Authorization: `Bearer ${currentUser.jwt}`,
          'Content-Type': 'multipart/form-data',
        },
      });


      // Update the userData state with the updated information
      setUserData((prevUserData) => {
        const updatedData = Object.fromEntries(formData.entries());
        delete updatedData.profile_pic;
        return { ...prevUserData, ...updatedData };
      });

      // Update user data in localStorage
      localStorage.setItem(
        'user',
        JSON.stringify({ ...currentUser, user: { ...currentUser.user, username: formData.get('username') } })
      );

      // Trigger storage event to update the Navbar component
      window.dispatchEvent(new StorageEvent('storage', { key: 'user', newValue: localStorage.getItem('user') }));

      // Clear the error message
      setErrorMessage('');


      // Clear the error message
      setErrorMessage('');

      // Switch back to the non-editing state and refresh user data
      setEditing(false);
      await refreshUserData();
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
      } else {
        console.error('Error:', error);
      }
    }
  };




  const checkForDuplicate = async (field, value) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users?${field}=${value}`
      );

      // Filter out the current user from the response data and make the comparison case-insensitive
      const filteredData = response.data.filter(user => user.id !== userData.id);

      const matchFound = filteredData.some(user => user[field].toLowerCase() === value.toLowerCase());

      return matchFound;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };






  return (
    <div className="stage settings">
      <h1>Settings</h1>
      {errorMessage && <p className="error">{errorMessage}</p>}
      {userData ? (
        <>
          {editing ? (
            <form onSubmit={handleEditSubmit}>
              {profilePicPreview && (
                <img className="profile-pic" src={profilePicPreview} alt="Profile preview" />
              )}
              <input
                type="file"
                id="profile_pic"
                name="profile_pic"
                onChange={handleProfilePicChange}
              />
              
              <label htmlFor="first_name">First Name:</label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                defaultValue={userData.first_name}
                
              />

              <label htmlFor="last_name">Last Name:</label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                defaultValue={userData.last_name}
                
              />
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                name="username"
                value={userData.username}
                onChange={(e) =>
                  setUserData((prevUserData) => ({
                    ...prevUserData,
                    username: e.target.value,
                  }))
                }
                required
              />
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                defaultValue={userData.email}
                required
              />

              <label htmlFor="bio">Bio:</label>
              <textarea
                id="bio"
                name="bio"
                defaultValue={userData.bio}
              />

              <button className="save" type="submit">Save</button>
            </form>
          ) : (
            <>
              {userData.profile_pic?.url && (
                <img
                  className="profile-pic"
                  src={
                    userData.profile_pic
                      ? `${getUrlPrefix()}${userData.profile_pic.url}`
                      : defaultProfilePic
                  }
                  alt={`${userData.first_name} ${userData.last_name}`}
                />
              )}
              <h3>First Name</h3>
              <h4>{userData.first_name}</h4>
              <h3>Last Name</h3>
              <h4>{userData.last_name}</h4>
              <h3>Username</h3>
              <h4>{userData.username}</h4>
              <h3>Email</h3>
              <h4>{userData.email}</h4>
              <h3>Bio</h3>
              <h4 className="bio"><ReactMarkdown>{userData.bio}</ReactMarkdown></h4>
            </>
          )}
          <button
            className={`cancel ${editing ? 'editing' : 'not-editing'}`}
            onClick={() => setEditing(!editing)}
          >
            {editing ? 'Cancel' : 'Edit'}
          </button>

        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Settings;
