import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavbarContext from './NavbarContext';

const Login = () => {
  const { updateNavbar } = useContext(NavbarContext);
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // First, authenticate the user
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/local`, {
        identifier,
        password,
      });

      const userId = response.data.user.id;

      // Then, get the following and followers data
      const responseUserData = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${userId}?populate=following&populate=followers`);

      // Combine the user data
      const completeUserData = {
        ...response.data,
        user: {
          ...response.data.user,
          following: responseUserData.data.following.map(user => user.id),
          followers: responseUserData.data.followers.map(user => user.id),
        }
      };

      localStorage.setItem('user', JSON.stringify(completeUserData));
      updateNavbar(); 
      navigate(`/${response.data.user.username}`);
    } catch (error) {
      setError('An error occurred during login. Please check your credentials and try again.');
    }
  };



  return (
    <div className="stage">
      <h1>Login</h1>
      {error && <p>{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username or Email"
          value={identifier}
          onChange={(event) => setIdentifier(event.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          required
        />
        <p><a className="forgot-password" href="/forgot-password">Forgot password?</a></p>
        <button className="btn-primary" type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
