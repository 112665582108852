import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import imageOne from './img/image_1.png';
import imageTwo from './img/image_2.png';
import imageThree from './img/image_3.png';
import Logo from './img/fitpholio-logo.svg';
import Sun from './img/sun.svg';
import Cold from './img/cold.svg';
import Heat from './img/sauna.svg';
import Workout from './img/workout.svg';


import Tests from './img/tests.png';
import { Helmet } from 'react-helmet';

const Home = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    console.log('user from local storage:', user);
    if (user && user.user && user.user.username) {
      console.log('navigating to profile');
      navigate(`/${user.user.username}`);
    }
  }, [user, navigate]);



  return (
    <>
      <Helmet>
        <title>Home - Fitpholio</title>
        <meta name="description" content="Simply track and share your workouts, metrics, and tests." />
        <meta property="og:title" content="Home - Fitpholio" />
        <meta property="og:description" content="Simply track and share your workouts, metrics, and tests." />
        <meta property="og:image" content="https://vacuous-miniature-wilderness.media.strapiapp.com/fitpholio_social_share_3346b17feb.png?updated_at=2023-05-02T22:30:53.554Z" />
      </Helmet>
      <div className="hero">
        <img className="logo" src={Logo} />
        <h1 className="title">Fitpholio</h1>
        <h2 className="tagline">Unlock Peak Wellness with fitpholio</h2>
        <p className="explanation">Achieve peak wellness with Fitpholio – your ultimate fitness companion. Master 4 research-backed fitness metrics and take fitness tests. Track your workout time, sunlight, heat, and cold exposure to optimize your performance. Get actionable insights from fitness tests to guide your next steps.</p>
        <Link className="btn-primary" to={`/register`}>Get started!</Link>
        <div className="product-points">
          <div className="product-point">
            <img className="product-image" src={imageOne} />
            <div>
              <h3>Simple Tracking & Enhanced Visibility</h3>
              <p>Fitpholio makes tracking each metric a breeze, granting you essential visibility into your wellness journey for better understanding.</p>
            </div>
          </div>
          <div className="product-point">
            <img className="product-image" src={imageTwo} />
            <div>
              <h3>Progress-Boosting Fitness Tests</h3>
              <p>Stay motivated by engaging in regular fitness tests with Fitpholio, keeping your progress in check and witnessing your improvement over time.</p>
            </div>
          </div>
          <div className="product-point">
            <img className="product-image" src={imageThree} />
            <div>
              <h3>User-Driven Action Plans</h3>
              <p>Fitpholio provides an intuitive dashboard, empowering you to create tailored action plans that align with your individual fitness goals.</p>
            </div>
          </div>
        </div>
        <div className="why-wrapper">
          <div className="fitness-metric-icons">
            <img className="product-image" src={Workout} />
            <img className="product-image" src={Sun} />
            <img className="product-image" src={Heat} />
            <img className="product-image" src={Cold} />
          </div>
          <p className="cta-copy">Embrace the key to peak wellness and revolutionize your fitness journey with Fitpholio today!</p>
          <Link className="btn-primary" to={`/register`}>Get started!</Link>
          <Link className="view-live-profile" to="/bgadoci">View an actual profile</Link>
        </div>
      </div>
    </>
  );
};

export default Home;