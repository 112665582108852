import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ component: Component }) => {
  const user = localStorage.getItem('user');

  if (!user) {
    return <Navigate to="/" />;
  }

  return <Component />;
};

export default ProtectedRoute;
