import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';
import defaultProfilePic from './img/placeholder.svg';
import { getUrlPrefix } from './utils/utils';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';

const FitnessTests = () => {
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userPosts, setUserPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { username } = useParams();
  const [isLoadingPosts, setIsLoadingPosts] = useState(true);
  const [isFiltering, setIsFiltering] = useState(false);
  const navigate = useNavigate();



  useEffect(() => {
    const fetchUserData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users?_limit=1&_q=${username}&populate=profile_pic&populate=posts`
      );
      const data = await response.json();

      if (data && data.length > 0) {
        setUserData(data[0]);
        setUserId(data[0].id);
        fetchUserPosts(data[0].id);
      } else {
        setUserData(null);
      }
    };

    fetchUserData();
  }, [username]);

  const fetchMorePosts = () => {
    setPage((prevPage) => prevPage + 1);
    fetchUserPosts(userId, page + 1);
  };

  const fetchUserPosts = async (userId) => {
    const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${userId}?populate=posts`);
    const user = userResponse.data;

    const sortedPosts = user.posts.sort((a, b) => new Date(b.date) - new Date(a.date));

    const filteredPosts = sortedPosts.filter((post) => post.type === 'Test');

    setUserPosts(filteredPosts);
    setIsLoadingPosts(false)
  };

  const groupPostsByMovementAndCategory = (posts) => {
    const groups = {};

    posts.forEach((post) => {
      const key = `${post.movement}__${post.score_category}`;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(post);
    });

    return groups;
  };

  const renderLineCharts = (groupedPosts) => {
      const charts = [];

      for (const key in groupedPosts) {
        const posts = groupedPosts[key];
        const [movement, scoreCategory] = key.split('__');

        if (!movement.toLowerCase().includes(search.toLowerCase())) {
          continue;
        }

        const data = posts
          .map((post) => ({
            date: post.date,
            score: post.score,
            scoreUnit: post.score_unit,
          }))
          .sort((a, b) => new Date(a.date) - new Date(b.date));

        const postId = posts[0].id; // Assuming that this is where the id comes from.

        charts.push(
          <div onClick={() => navigate(`/${username}/posts/${postId}`)} key={key}>
            <div className={`movement-chart ${isFiltering ? 'hidden' : 'visible'}`} style={{cursor: 'pointer'}}>
              <h2 className="post-movement">{movement}</h2>
              <h3 className="post-score-category">{scoreCategory}</h3>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid 
                    strokeDasharray="3 3" 
                    stroke="transparent"
                  />
                  <XAxis 
                    dataKey="date" 
                    tickCount={10} 
                    tickSize={5} 
                    tickMargin={10}                
                    tick={{fontSize: 14, fill: "#64748b"}} 
                    axisLine={{ stroke: "#64748b" }}
                  />
                  <YAxis
                    tickCount={10} 
                    tickSize={5} 
                    tickMargin={10}
                    tick={{fontSize: 14, fill: "#64748b"}} 
                    axisLine={{ stroke: "#64748b" }}
                  >
                    <Label
                      value={posts[0].score_unit}
                      angle={-90}
                      position="insideLeft"
                      offset={-10}
                      style={{
                        textAnchor: 'middle',
                        fontSize: 14,
                        fill: '#34d399',
                        paddingBottom: `50px`,
                      }}
                    />
                  </YAxis>
                  <Tooltip contentStyle={{ 
                    color: '#64748b', 
                    backgroundColor: '#0f172a',
                    borderRadius: '10px',
                    borderColor: '#34d399',
                    boxShadow: '0px 0px 20px rgb(52, 211, 153, .2)',
                    padding: '10px 15px'
                    }} />
                  <Line 
                    type="monotone" 
                    dataKey="score" 
                    stroke="#34d399" 
                    strokeWidth={2}
                    activeDot={{ r: 8 }} 
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        );
      }

      return charts;
  };


  const renderEmptyState = () => {
    return (
      <div className="no-tests">
        <div className="no-fitness-tests">Test charts will populate when data is available.</div>
      </div>
    );
  };

  return (
    <div className="stage fitness-tests">
      {userData && (
        <>
          <Helmet>
            <title>{userData.username}'s fitness tests on Fitpholio</title>
            <meta name="description" content={`${userData.username} tracks workouts, metrics, and tests on Fitpholio.`} />
            <meta property="og:title" content={`${userData.username}'s fitness tests on Fitpholio`} />
            <meta property="og:description" content={`${userData.username} tracks workouts, metrics, and tests on Fitpholio.`} />
            <meta property="og:image" content="https://vacuous-miniature-wilderness.media.strapiapp.com/fitpholio_social_share_3346b17feb.png?updated_at=2023-05-02T22:30:53.554Z" />
          </Helmet>
          <div className="profile-header">
            <div className="profile-links">
              <Link className="inactive" to={`/${userData.username}/stats`}>Stats</Link>
              <Link className="inactive" to={`/${userData.username}/notes`}>Notes</Link>
              <Link className="active" to={`/${userData.username}/fitness-tests`}>Tests</Link>
              <Link className="inactive" to={`/${userData.username}`}>Timeline</Link>
            </div>
            <>
              <div>
                <Link to={`/${userData.username}`}>
                  <img
                    className="profile-pic"
                    src={
                      userData.profile_pic
                        ? `${getUrlPrefix()}${userData.profile_pic.url}`
                        : defaultProfilePic
                    }
                    alt={`${userData.first_name} ${userData.last_name}`}
                  />
                </Link>
              </div>
              <h1 className="big-name">
                {userData.first_name && userData.last_name
                  ? `${userData.first_name} ${userData.last_name}`
                  : userData.username}
              </h1>
              {userData.first_name && userData.last_name ? (
                <p className="small-name">@{userData.username}</p>
              ) : null}

              <h2 className="bio"><ReactMarkdown>{userData.bio}</ReactMarkdown></h2>
            </>
          </div>
          <div className="filter-container">
            <input
              className="filter-tests"
              type="text"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setIsFiltering(true);
                setTimeout(() => setIsFiltering(false), 500);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  setSearch('');
                }
              }}
              placeholder="Filter Tests..."
            />
            {search && (
              <span 
                className="clear-filter"
                onClick={() => setSearch('')}
              >
                ✖
              </span>
            )}

          </div>

        </>
      )}
      {isLoadingPosts ? (
        <h4>Loading...</h4>
      ) : userPosts.length > 0 ? (
        renderLineCharts(groupPostsByMovementAndCategory(userPosts))
      ) : (
        renderEmptyState()
      )}
    </div>
  );

};

export default FitnessTests;
