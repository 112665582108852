import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import defaultProfilePic from './img/placeholder.svg';
import { getUrlPrefix } from './utils/utils';
import axios from 'axios';

const Following = () => {
  const { username } = useParams();
  const [user, setUser] = useState(null);
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const setCurrentUser = (userData) => {
      localStorage.setItem('user', JSON.stringify(userData));
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/users?username=${username}`)
      .then((response) => response.json())
      .then((data) => {
        const exactMatchUser = data.find(user => user.username === username);

        if (exactMatchUser) {
          const userId = exactMatchUser.id;

          fetch(`${process.env.REACT_APP_API_URL}/api/users/${userId}?populate[following][populate][0]=posts&populate[following][populate][1]=profile_pic`)
            .then((response) => response.json())
            .then((userData) => {
              setUser(userData);
            })
            .catch((error) => console.error(error));
        } else {
          console.error('User not found');
        }
      })
      .catch((error) => console.error(error));
  }, [username]);

  if (!user) {
    return <div>Loading...</div>;
  }

  const unfollowUser = async (followedUser) => {
    if (followedUser && currentUser) {
      if (!currentUser.user.id) {
        console.error('currentUser.id is not defined');
        return;
      }
      try {
        const currentFollowing = Array.isArray(currentUser.user.following) ? currentUser.user.following : [];
        const updatedFollowing = currentFollowing.filter(id => id !== followedUser.id);

        await axios.put(`${process.env.REACT_APP_API_URL}/api/users/${currentUser.user.id}`, {
          following: updatedFollowing,
        }, {
          headers: {
            Authorization: `Bearer ${currentUser.jwt}`,
          },
        });

        const currentFollowers = Array.isArray(followedUser.followers) ? followedUser.followers : [];
        const updatedFollowers = currentFollowers.filter(id => id !== currentUser.user.id);

        await axios.put(`${process.env.REACT_APP_API_URL}/api/users/${followedUser.id}`, {
          followers: updatedFollowers,
        }, {
          headers: {
            Authorization: `Bearer ${currentUser.jwt}`,
          },
        }).catch(error => {
          console.error('Failed to update user followers: ', error);
        });

        // After updating the database, update currentUser and userData in your app
        const updatedCurrentUser = { ...currentUser };
        updatedCurrentUser.user.following = updatedFollowing;
        setCurrentUser(updatedCurrentUser);

        setUser(prevUser => {
          const updatedUser = { ...prevUser };
          updatedUser.following = prevUser.following.filter(user => user.id !== followedUser.id);
          return updatedUser;
        });

      } catch (error) {
        console.error(error);
      }
    } else {
      console.error('Either currentUser or userData is not defined');
    }
  };


  return (
    <div className="stage following">

      <h1>
      		<Link to={`/${username}`}>
      			{username}
      		</Link>
      		<span> is following</span>
      </h1>
      {user.following.map((followingUser) => (
        <div className="following-user" key={followingUser.id}>
	        <Link to={`/${followingUser.username}`}>
		        <div className="left-items">
	        	  <img
	        	    className="profile-pic"
	        	    src={
	        	      followingUser.profile_pic
	        	        ? `${getUrlPrefix()}${followingUser.profile_pic.formats.thumbnail.url}`
	        	        : defaultProfilePic
	        	    }
	        	    alt={`${followingUser.first_name} ${followingUser.last_name}`}
	        	  />
		        	<div className="username">
		          	<h2>{followingUser.username}</h2>
		          	<p className="bio">{followingUser.bio}</p>
		          </div>
		        </div>
		      </Link>
          <div className="unfollow">
          	{username === currentUser.user.username && <button className="class-for-unfollow" onClick={() => unfollowUser(followingUser)}>Unfollow</button>}
          </div>

        </div>
      ))}
    </div>
  );
};

export default Following;
