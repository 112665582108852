import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Updates = () => {
  const [updates, setUpdates] = useState([]);
  const [update, setUpdate] = useState({
    description: '',
    date: '',
    type: 'fix',
    status: 'backlog', 
  });

  const [filterType, setFilterType] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const userItem = JSON.parse(localStorage.getItem('user'));
  const user = userItem ? userItem.user : null; // Get user from local storage, or null if not present

  useEffect(() => {
    const fetchUpdates = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/updates`);
        const sortedUpdates = res.data.data.sort((a, b) => new Date(b.attributes.date) - new Date(a.attributes.date));
        setUpdates(sortedUpdates);
      } catch (err) {
        console.error(err);
      }
    };

    fetchUpdates();
  }, []);

  const handleChange = (e) => {
    setUpdate({
      ...update,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const userItem = JSON.parse(localStorage.getItem('user'));
    const userToken = userItem ? userItem.jwt : null; // Get JWT from local storage, or null if not present

    if(userToken) { // Only attempt to make the request if userToken is not null
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/updates`, {data: update}, {
          headers: {
            Authorization: `Bearer ${userToken}`, // Use Bearer token authentication
          },
        });

        if(res.data.data) { // Ensure res.data.data is defined before updating state
          setUpdates([res.data.data, ...updates]); 
        } else {
          console.error("Unexpected response format: ", res.data);
        }
        
        setUpdate({ description: '', date: '', type: 'fix', status: 'backlog' });

      } catch (err) {
        console.error(err.response.data);
      }
    } else {
      // Handle the case where userToken is null (i.e., user is not logged in)
      console.error("User is not logged in.");
    }
  };

  const handleDelete = async (id) => {
    try {
      const userToken = JSON.parse(localStorage.getItem('user')).jwt; // Get JWT from local storage
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/updates/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`, // Use Bearer token authentication
        },
      });

      // Remove the update from the local state
      const newUpdates = updates.filter((update) => update.id !== id);
      setUpdates(newUpdates);
    } catch (err) {
      console.error(err);
    }
  };

  // Change handlers for the filters
  const handleTypeFilterChange = (e) => {
    setFilterType(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setFilterStatus(e.target.value);
  };

  const handleStatusUpdate = async (updateId, newStatus) => {
    const userToken = JSON.parse(localStorage.getItem('user')).jwt; // Get JWT from local storage
    try {
      // Update the status in the database
      await axios.put(`${process.env.REACT_APP_API_URL}/api/updates/${updateId}`, {
        data: {
          status: newStatus,
        },
      }, {
        headers: {
          Authorization: `Bearer ${userToken}`, // Use Bearer token authentication
        },
      });
      // Update the status in the local state
      const newUpdates = updates.map((update) => {
        if (update.id === updateId) {
          return {...update, attributes: {...update.attributes, status: newStatus}};
        }
        return update;
      });
      setUpdates(newUpdates);
    } catch (err) {
      console.error(err);
    }
  };


  // Filtered updates
  const filteredUpdates = updates
    .filter(update => filterType === '' || update.attributes.type === filterType)
    .filter(update => filterStatus === '' || update.attributes.status === filterStatus);


  return (
    <div className="stage updates">
      <h1>fitpholio platform updates...</h1>
      {user && user.username === 'bgadoci' && (
        <form className="update-form" onSubmit={handleSubmit}>
          <div className="inputs-row">
            <label className="type-label">
              <select
                className="type-input"
                name='type'
                value={update.type}
                onChange={handleChange}
              >
                <option value="issue">Issue</option>
                <option value="fix">Fix</option>
                <option value="feature">Feature</option>
              </select>
            </label>
            <label className="status-label">
              <select
                className="status-input"
                name='status'
                value={update.status}
                onChange={handleChange}
              >
                <option value="backlog">Backlog</option>
                <option value="in progress">In Progress</option>
                <option value="complete">Complete</option>
              </select>
            </label>
            <label className="date-label">
              <input
                className="date-input"
                type='date'
                name='date'
                value={update.date}
                onChange={handleChange}
              />
            </label>
            <label className="description-label">
              <input
                placeholder="Update content..."
                className="description-input"
                type='text'
                name='description'
                value={update.description}
                onChange={handleChange}
              />
            </label>
          </div>
          <button className="submit-button" type="submit">Create Update</button>
        </form>


      )}
      <div className="filters">
        <select onChange={handleTypeFilterChange}>
          <option value="">Filter by type...</option>
          <option value="issue">Issue</option>
          <option value="fix">Fix</option>
          <option value="feature">Feature</option>
        </select>
        <select onChange={handleStatusFilterChange}>
          <option value="">Filter by status...</option>
          <option value="backlog">Backlog</option>
          <option value="in progress">In Progress</option>
          <option value="complete">Complete</option>
        </select>
      </div>
      {filteredUpdates.map(update => {
        const date = new Date(update.attributes.date);
        const formattedDate = date.toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit'
        }).replace(/\//g, '-');

        // Prepare a string of class names, appending the update type
        const updateClasses = `update ${update.attributes.type}`;

        return (
          <div className={updateClasses} key={update.id}>
            <div className="update-icon"></div>
            <div className="update-content">
              <p className="date">{formattedDate}</p>
              <p className="description"><span>{update.attributes.type}: </span>{update.attributes.description}</p>
              <div className={`update-status ${update.attributes.status ? (update.attributes.status || 'default-status').replace(/ /g, '-') : ''}`}>{update.attributes.status || 'default-status'}</div>
            </div>
            {user && user.username === 'bgadoci' && 
            <div>
              <button className="update-delete" onClick={() => handleDelete(update.id)}>Delete</button>
              <select 
								  className="update-status-select" 
								  value={update.attributes.status || 'backlog'} // provide a default value
								  onChange={e => handleStatusUpdate(update.id, e.target.value)}
								>
                <option value="backlog">Backlog</option>
                <option value="in progress">In Progress</option>
                <option value="complete">Complete</option>
              </select>
            </div>}
          </div>
        );
      })}
    </div>
  );
};

export default Updates;
