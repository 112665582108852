import React from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';

const MovementChart = ({ posts, movement, postDate, score_category }) => {
  const currentScoreCategory = score_category;
  
  const endOfPostDate = new Date(postDate);
  endOfPostDate.setHours(23, 59, 59, 999);

  const filteredPosts = posts.filter(
    (post) =>
      post.type === 'Test' &&
      post.movement === movement &&
      post.score_category === currentScoreCategory &&
      new Date(post.date) <= endOfPostDate
  );



  const groupPostsByMovementAndCategory = (posts) => {
    const groupedPosts = {};

    posts.forEach((post) => {
      const key = `${post.movement}|${post.score_category}`;

      if (!groupedPosts[key]) {
        groupedPosts[key] = [];
      }

      groupedPosts[key].push(post);
    });

    return groupedPosts;
  };

  const renderLineCharts = (groupedPosts) => {
    const charts = [];

    for (const key in groupedPosts) {
      const posts = groupedPosts[key];
      const [movement, scoreCategory] = key.split('|');
      const data = posts
        .map((post) => ({
          date: post.date,
          score: post.score,
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      charts.push(
        <div className="movement-chart" key={key}>
          <h3 className="post-score-category">{scoreCategory}</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid 
                strokeDasharray="3 3" 
                stroke="transparent"
              />
              <XAxis 
                dataKey="date" 
                tickCount={10} 
                tickSize={5} 
                tickMargin={10}
                tick={{fontSize: 14, fill: "#64748b"}} 
                axisLine={{ stroke: "#64748b" }}
              />
              <YAxis
                tickCount={10} 
                tickSize={5} 
                tickMargin={10}
                tick={{fontSize: 14, fill: "#64748b"}} 
                axisLine={{ stroke: "#64748b" }}
              >
                <Label
                  value={posts[0].score_unit}
                  angle={-90}
                  position="insideLeft"
                  offset={5}
                  style={{
                    textAnchor: 'middle',
                    fontSize: 14,
                    fill: '#34d399',
                  }}
                />
              </YAxis>
              <Tooltip contentStyle={{ 
                color: '#64748b', 
                backgroundColor: '#0f172a',
                borderRadius: '10px',
                borderColor: '#34d399',
                boxShadow: '0px 0px 20px rgb(52, 211, 153, .2)',
                padding: '10px 15px'
                }} />
              <Line 
                type="monotone" 
                dataKey="score" 
                stroke="#34d399" 
                strokeWidth={2}
                activeDot={{ r: 8 }} 
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      );
    }

    return charts;
  };

  return (
    <div>
      {renderLineCharts(groupPostsByMovementAndCategory(filteredPosts))}
    </div>
  );
};

export default MovementChart;
