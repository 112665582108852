import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getUrlPrefix } from './utils/utils';
import defaultProfilePic from './img/placeholder.svg';
import ReactMarkdown from 'react-markdown';

const Notes = () => {
  const { username } = useParams();
  const [user, setUser] = useState(null);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/users?username=${username}`)
      .then((response) => response.json())
      .then((data) => {
        const exactMatchUser = data.find(user => user.username === username);

        if (exactMatchUser) {
          const userId = exactMatchUser.id;

          fetch(`${process.env.REACT_APP_API_URL}/api/users/${userId}?populate=posts&populate=profile_pic`)
            .then((response) => response.json())
            .then((userData) => {
              setUser(userData);
              
              // Sort posts by date in descending order (most recent first)
              const sortedNotes = userData.posts
                .filter(post => post.type === 'Note')
                .sort((a, b) => new Date(b.date) - new Date(a.date));

              setNotes(sortedNotes);
            })
            .catch((error) => console.error(error));
        } else {
          console.error('User not found');
        }
      })
      .catch((error) => console.error(error));
  }, [username]);


  if (!user) {
    return <div>Loading...</div>;
  }

  return (
      <div className="stage notes">
        <div className="profile-header">
          <div className="profile-links">
            <Link className="inactive" to={`/${user.username}/stats`}>Stats</Link>
            <Link className="active" to={`/${user.username}/notes`}>Notes</Link>
            <Link className="inactive" to={`/${user.username}/fitness-tests`}>Tests</Link>
            <Link className="inactive" to={`/${user.username}`}>Timeline</Link>
          </div>
          <>
            <div>
              <Link to={`/${user.username}`}>
                <img
                  className="profile-pic"
                  src={
                    user.profile_pic
                      ? `${getUrlPrefix()}${user.profile_pic.url}`
                      : defaultProfilePic
                  }
                  alt={`${user.first_name} ${user.last_name}`}
                />
              </Link>
            </div>
            <h1 className="big-name">
              {user.first_name && user.last_name
                ? `${user.first_name} ${user.last_name}`
                : user.username}
            </h1>
            {user.first_name && user.last_name ? (
              <p className="small-name">@{user.username}</p>
            ) : null}

            <h2 className="bio"><ReactMarkdown>{user.bio}</ReactMarkdown></h2>
          </>
        </div>
        <h3>Notes</h3>
        {notes.length > 0 ? (
          notes.map(note => (
            <div className="Note" key={note.id}>
              <div className="post-icon"></div>
              <div className="post-content">
                <p className="post-date">{note.date}</p>
                <ReactMarkdown className="post-description">{note.description}</ReactMarkdown>
              </div>
            </div>
          ))
        ) : (
          <p>No notes yet.</p>
        )}
      </div>
    );
  };

  export default Notes;
