import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import MovementChart from './MovementChart';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactMarkdown from 'react-markdown';
import defaultProfilePic from './img/placeholder.svg';
import { getUrlPrefix } from './utils/utils';

const Stream = () => {
  const [posts, setPosts] = useState({});
  const [page, setPage] = useState(1);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const navigate = useNavigate();
  const getMoodClass = (value) => {
    if (value >= 1 && value <= 3) return 'bad';
    if (value >= 4 && value <= 7) return 'meh';
    if (value >= 8 && value <= 10) return 'good';
    return '';
  };

  useEffect(() => {
    fetchUserPosts(page);
  }, [page]);

  const fetchUserPosts = async (page = 1, pageSize = 10) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));

    if (currentUser) {
      const currentUserId = currentUser.user.id;

      axios.get(`${process.env.REACT_APP_API_URL}/api/users/${currentUserId}?populate[following][populate][0]=posts,posts.&populate[following][populate][1]=profile_pic`)
        .then(async res => {
          const followingUsers = res.data.following;

          if (followingUsers && followingUsers.length > 0) {
            let newPosts = followingUsers.flatMap(user =>
              user.posts.map(post => ({
                ...post,
                user: {
                  id: user.id,
                  username: user.username,
                  profile_pic: user.profile_pic,
                },
              }))
            );

            // fetch all posts for each user who has a 'Test' post
            const testPostUsers = newPosts.filter(post => post.type === 'Test').map(post => post.user.id);
            const uniqueTestPostUsers = [...new Set(testPostUsers)];
            await Promise.all(uniqueTestPostUsers.map(async userId => {
              const userPostsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${userId}?populate=posts`);
              newPosts.forEach(post => {
                if(post.user.id === userId) {
                  post.allPosts = userPostsResponse.data.posts;
                }
              });
            }));

            const sortedPosts = newPosts.sort((a, b) => new Date(b.date) - new Date(a.date));

            const paginatedPosts = sortedPosts.slice(0, page * pageSize);

            const groupedPosts = paginatedPosts.reduce((acc, post) => {
              const date = post.date.split('T')[0];
              if (!acc[date]) acc[date] = [];
              acc[date].push(post);
              return acc;
            }, {});

            setPosts(groupedPosts);

            if (sortedPosts.length <= paginatedPosts.length) {
              setHasMorePosts(false);
            }

          } else {
            console.log('User is not following anyone');
          }
        })
        .catch(error => {
          console.error('Error fetching posts:', error);
        });
    } else {
      console.log('User is not logged in');
    }
  };






  const fetchMorePosts = () => {
    setPage((prevPage) => prevPage + 1);
  };


  const formatDateWithDay = (dateString) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(dateString);
    const day = days[date.getDay()];
    const formattedDate = `${day}, ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  };
  

  return (
    <>
      <div className="stage stream">
        <h1>Streams</h1>
	      <InfiniteScroll
          style={{ overflow: 'visible' }}
	        dataLength={Object.keys(posts).length}
	        next={fetchMorePosts}
	        hasMore={hasMorePosts}
	        loader={<h4>Loading...</h4>}
	        endMessage={
	          <p className="no-more-posts">&#127881; Yay! You have seen it all. No more posts are available.</p>
	        }
	      >
	        {Object.entries(posts).sort((a, b) => new Date(b[0]) - new Date(a[0])).map(([date, posts]) => (
	          <div key={date}>
	            <h3 className="timeline-date">{formatDateWithDay(date)}</h3>
	            {posts.map((post) => {
	              const postUser = post.user;
	              const profilePic = postUser.profile_pic;
	              return (
	                <div 
                    key={post.id} 
                    className={`${post.type} ${post.photo && post.type === 'Workout' ? 'workout-with-photo' : ''}`}
                    style={post.photo && post.type === 'Workout' ? {backgroundImage: `url(${getUrlPrefix()}${post.photo.url})`, backgroundSize: 'cover', backgroundPosition: 'center', position: 'relative'} : {}}
                    onClick={() => navigate(`/${postUser.username}/posts/${post.id}`)}
                  >
                    <div style={{position: 'relative', zIndex: 2}}>
  	                  <div className="post-icon"></div>
  	                  <div className="post-content">
  	                    <h4 className="post-type">{post.type}</h4>
  	                    <p className="post-date">{post.date}</p>

  	                    <ReactMarkdown className="post-description">{post.description}</ReactMarkdown>
  		                    <p className="post-minutes">{post.minutes} minutes</p>
                          {post.type === 'Checkin' ? (
                            <>
                              <p className={`post-mood ${getMoodClass(post.mood)}`}>Mood: {post.mood}</p>
                              <p className={`post-energy ${getMoodClass(post.energy)}`}>Energy: {post.energy}</p>
                              <p className={`post-sleep ${getMoodClass(post.hours_of_sleep)}`}>Hours of Sleep: {post.hours_of_sleep}</p>
                            </>
                          ) : (
                            <>
                              <ReactMarkdown className="post-description">{post.description}</ReactMarkdown>
                              <p className="post-minutes">{post.minutes} minutes</p>
                            </>
                          )}
                          {post.type === 'Test' && (
                            <div>
                              <h4 className="post-movement">{post.movement}</h4>
                              <MovementChart
                                posts={post.allPosts}
                                movement={post.movement}
                                postDate={post.date}
                                score_category={post.score_category}
                              />
                            </div>
                          )}

  		                    <Link to={`/${postUser.username}`} className="user-wrapper">
                            <div className="user-wrapper-inner">
    		                      <img 
    		                        src={profilePic ? `${process.env.REACT_APP_API_URL}${profilePic.url}` : defaultProfilePic} 
    		                        alt={`${postUser.username}'s profile`} 
    		                      />
    	                    	  <p className="post-user">
                                {postUser.username}
                              </p>
                            </div>
                          </Link>
  	                  </div>
  	                </div>
                  </div>
	              );
	            })}
	          </div>
	        ))}

	      </InfiniteScroll>
	    </div>
    </>
  );
};

export default Stream;
