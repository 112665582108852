import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Register from './Register';
import Login from './Login';
import Profile from './Profile';
import Settings from './Settings';
import Navbar from './Navbar';
import Home from './Home';
import FitnessTests from './FitnessTests';
import StatsPage from './StatsPage';
import ProtectedRoute from './ProtectedRoute';
import NavbarContext from './NavbarContext';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Dashboard from "./Dashboard";
import Stream from "./Stream";
import Following from "./Following";
import Followers from "./Followers";
import Notes from "./Notes";
import Updates from "./Updates";
import Post from "./Post";

const App = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [updateNavbarCount, setUpdateNavbarCount] = useState(0);

  const updateNavbar = useCallback(() => {
    setUpdateNavbarCount((count) => count + 1);
  }, []);
  return (
    <Router>
      <div>
        <NavbarContext.Provider value={{ updateNavbar }}>
          <Navbar key={updateNavbarCount} />
          <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/:username/posts/:id" element={<Post />} />
          <Route path="/:username" element={<Profile />} />
          <Route path="/:username/fitness-tests" element={<FitnessTests />} />
          <Route path="/:username/stats" element={<StatsPage />} />
          <Route path="/settings" element={<ProtectedRoute component={Settings} />} />  
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/:username/following" element={<Following />} />
          <Route path="/:username/followers" element={<Followers />} />
          <Route path="/:username/notes" element={<Notes />} />
          <Route path="/stream" element={<Stream />} />
          <Route path="/updates" element={<Updates />} />

        </Routes>
      </NavbarContext.Provider>
    </div>
  </Router>
  );
};

export default App;
