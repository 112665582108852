import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import imageMark from './img/fitpholio-mark.svg';


const Navbar = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'user') {
        setUser(JSON.parse(e.newValue));
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('user');
    setUser(null);
    navigate('/');
  };

  return (
  <nav>
    {user ? (
      <>
        <Link to={`/${user.user.username}`}>
            <img src={imageMark} />
          </Link>
          <Link to={`/${user.user.username}`}>Profile</Link>
          {user.user.username === 'bgadoci' && <Link to="/dashboard">Dashboard</Link>}
          <Link to={`/stream`}>Stream</Link>
          <Link to={`/settings`}>Settings</Link>
          <Link to="/updates">Updates</Link>
          <button className="logout" onClick={handleLogout}>Logout</button>
        </>
      ) : (
        <>
          <Link to="/">
            <img src={imageMark} />
          </Link>
          <Link to="/register">Register</Link>
          <Link to="/login">Login</Link>
          <Link to="/updates">Updates</Link>
        </>
      )}
    </nav>
  );
};

export default Navbar;
