import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import NavbarContext from './NavbarContext';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  const { updateNavbar } = useContext(NavbarContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== passwordConfirmation) {
      setError('Passwords do not match. Please try again.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/reset-password`, {
        code,
        password,
        passwordConfirmation,
      });
      localStorage.setItem('user', JSON.stringify(response.data));
      updateNavbar();
      navigate(`/${response.data.user.username}`);
    } catch (error) {
      setError('An error occurred. Please try another password.');
    }
  };

  return (
    <div className="stage">
      <h1>Reset Password</h1>
      {error && <p>{error}</p>}
      <form onSubmit={handleSubmit}>
        <label>Password</label>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          required
        />
        <label>Confirm</label>
        <input
          type="password"
          placeholder="Password"
          value={passwordConfirmation}
          onChange={(event) => setConfirmPassword(event.target.value)}
          required
        />
        <button className="btn-primary" type="submit">Reset</button>
      </form>
    </div>
  );
};

export default ResetPassword;
