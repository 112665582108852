import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Stat from './Stat';
import { Helmet } from 'react-helmet';
import { getUrlPrefix } from './utils/utils';
import defaultProfilePic from './img/placeholder.svg';
import ReactMarkdown from 'react-markdown';

const Stats = () => {
  const { username } = useParams();
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [dateFilter, setDateFilter] = useState(7);
  const [initialLoad, setInitialLoad] = useState(true);
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [bio, setBio] = useState(null);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
  const fetchUserId = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users?_limit=1&_q=${username}&populate=profile_pic`
    );
    const data = await response.json();

    if (data && data.length > 0) {
      setUserId(data[0].id);
      setUserData(data[0]);
      console.log("Profile Pic Data: ", data[0].profile_pic); 
      setFirstName(data[0].first_name);
      setLastName(data[0].last_name);
      setBio(data[0].bio);
      setInitialLoad(false);
    } else {
      // Handle the case when data is not available
    }
  };
  fetchUserId();
}, [username]);

// fetch this just once
useEffect(() => {
  const fetchPosts = async () => {
    // don't run if we don't have a userId
    if (!userId) return null;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${userId}?populate=posts`);
    setPosts(response.data.posts);
  };
  fetchPosts();
}, [userId]);

  const dateRanges = [7, 30, 90, 180, 365, null];



  return (
    <div className="stage stats">
	    <Helmet>
	      <title>{username}'s fitness metrics on Fitpholio</title>
	      <meta name="description" content={`${username} tracks workouts, metrics, and tests on Fitpholio.`} />
	      <meta property="og:title" content={`${username}'s fitness metrics on Fitpholio`} />
	      <meta property="og:description" content={`${username} tracks workouts, metrics, and tests on Fitpholio.`} />
	      <meta property="og:image" content="https://vacuous-miniature-wilderness.media.strapiapp.com/fitpholio_social_share_3346b17feb.png?updated_at=2023-05-02T22:30:53.554Z" />
	    </Helmet>
	    <div className="profile-header">
	      <div className="profile-links">
	        <a className="active" href={`/${username}/stats`}>Stats</a>
          <Link className="inactive" to={`/${username}/notes`}>Notes</Link>
          <a className="inactive" href={`/${username}/fitness-tests`}>Tests</a>
	        <a className="inactive" href={`/${username}`}>Timeline</a>  
	      </div>
        <div>
          <Link to={`/${username}`}>
            {
              userData && (
                <img
                  className="profile-pic"
                  src={
                    userData.profile_pic
                      ? `${getUrlPrefix()}${userData.profile_pic.url}`
                      : defaultProfilePic
                  }
                  alt={`${firstName} ${lastName}`}
                />
              )
            }
          </Link>
        </div>

        <h1 className="big-name">
          {firstName && lastName
            ? `${firstName} ${lastName}`
            : username}
        </h1>
        {firstName && lastName ? (
          <p className="small-name">@{username}</p>
        ) : null}
        <h2 className="bio"><ReactMarkdown>{bio}</ReactMarkdown></h2>
	      
	    </div>
      <div className="stats-nav">
        <h3 className="time-period">Time period</h3>
        <div className="movement-chart">
          {dateRanges.map((range) => (
              <a
                key={range}
                className={range === dateFilter ? 'active' : ''}
                onClick={() => setDateFilter(range)}
              >
                {range ? `Last ${range} days` : 'All time'}
              </a>
            ))}
        </div>
      </div>
      {loading && <div className="loading-alert">Loading stats...</div>}
      {!initialLoad && userId && (
        <>
          <h3 className="section-heading">Averages</h3>
          <h4 className="section-info">This section shows averages per session in the time period</h4>
          <div className="stats-wrapper">
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement={null}
              scoreCategory={null}
              scoreUnit={null}
              dateFilter={dateFilter}
              operation="average"
              postType="Workout"
              
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement={null}
              scoreCategory={null}
              scoreUnit={null}
              dateFilter={dateFilter}
              operation="average"
              postType="Sun"
              
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Deadlift"
              scoreCategory="One Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Deadlift"
              scoreCategory="Ten Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Deadlift"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Power Clean"
              scoreCategory="One Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Power Clean"
              scoreCategory="Ten Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Power Clean"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Squat Clean"
              scoreCategory="One Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Squat Clean"
              scoreCategory="Ten Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Squat Clean"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Back Squat"
              scoreCategory="One Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Back Squat"
              scoreCategory="Ten Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Back Squat"
              scoreCategory="Twenty Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Back Squat"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Front Squat"
              scoreCategory="One Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Front Squat"
              scoreCategory="Ten Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Front Squat"
              scoreCategory="Twenty Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Front Squat"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Shoulder Press"
              scoreCategory="One Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Shoulder Press"
              scoreCategory="Ten Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Shoulder Press"
              scoreCategory="Twenty Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Shoulder Press"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Push Press"
              scoreCategory="One Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Push Press"
              scoreCategory="Ten Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Push Press"
              scoreCategory="Twenty Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Push Press"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Bench Press"
              scoreCategory="One Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Bench Press"
              scoreCategory="Ten Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Bench Press"
              scoreCategory="Twenty Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Floor Press"
              scoreCategory="One Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Floor Press"
              scoreCategory="Ten Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Floor Press"
              scoreCategory="Twenty Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Bench Press"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Pull-ups"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Pull-ups"
              scoreCategory="One Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Pull-ups"
              scoreCategory="Ten Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Pull-ups"
              scoreCategory="Twenty Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Pull-ups"
              scoreCategory="Thirty Second Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Pull-ups"
              scoreCategory="One Minute Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Push-ups"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Push-ups"
              scoreCategory="One Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Push-ups"
              scoreCategory="Ten Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Push-ups"
              scoreCategory="Twenty Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Push-ups"
              scoreCategory="Thirty Second Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Push-ups"
              scoreCategory="One Minute Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Handstand Push-ups"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Handstand Push-ups"
              scoreCategory="Thirty Second Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Handstand Push-ups"
              scoreCategory="One Minute Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Burpees"
              scoreCategory="Thirty Second Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Burpees"
              scoreCategory="One Minute Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Burpees"
              scoreCategory="Three Hundred Reps"
              scoreUnit="Minutes"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Bar Dips"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Bar Dips"
              scoreCategory="One Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Bar Dips"
              scoreCategory="Ten Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Bar Dips"
              scoreCategory="Thirty Second Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Bar Dips"
              scoreCategory="One Minute Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Ring Dips"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Ring Dips"
              scoreCategory="One Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Ring Dips"
              scoreCategory="Ten Rep Max"
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Ring Dips"
              scoreCategory="Thirty Second Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Ring Dips"
              scoreCategory="One Minute Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Ring Muscle-ups"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Ring Muscle-ups"
              scoreCategory="Thirty Second Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Ring Muscle-ups"
              scoreCategory="One Minute Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Bar Muscle-ups"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Bar Muscle-ups"
              scoreCategory="Thirty Second Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Bar Muscle-ups"
              scoreCategory="One Minute Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Double Unders"
              scoreCategory="Max Reps"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Double Unders"
              scoreCategory="Thirty Second Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Double Unders"
              scoreCategory="One Minute Test"
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Row"
              scoreCategory="Thirty Second Test"
              scoreUnit="Calories"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Row"
              scoreCategory="Thirty Second Test"
              scoreUnit="Meters"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Row"
              scoreCategory="One Minute Test"
              scoreUnit="Calories"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Row"
              scoreCategory="One Minute Test"
              scoreUnit="Meters"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Row"
              scoreCategory="Meters (400)"
              scoreUnit="Minutes"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Row"
              scoreCategory="Meters (800)"
              scoreUnit="Minutes"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Row"
              scoreCategory="One Mile"
              scoreUnit="Minutes"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Assault Bike"
              scoreCategory="Thirty Second Test"
              scoreUnit="Calories"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Assault Bike"
              scoreCategory="Thirty Second Test"
              scoreUnit="Meters"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Assault Bike"
              scoreCategory="One Minute Test"
              scoreUnit="Calories"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Assault Bike"
              scoreCategory="One Minute Test"
              scoreUnit="Meters"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Assault Bike"
              scoreCategory="Meters (400)"
              scoreUnit="Minutes"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Assault Bike"
              scoreCategory="Meters (800)"
              scoreUnit="Minutes"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Assault Bike"
              scoreCategory="One Mile"
              scoreUnit="Minutes"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Run"
              scoreCategory="Meters (400)"
              scoreUnit="Minutes"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Run"
              scoreCategory="Meters (800)"
              scoreUnit="Minutes"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />

            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Run"
              scoreCategory="One Mile"
              scoreUnit="Minutes"
              dateFilter={dateFilter}
              operation="average"
              postType={"Test"}
            />


            
          </div>
          <h3 className="section-heading">Minute Totals</h3>
          <h4 className="section-info">Total minutes of working out, sun, sauna, and cold</h4>
          <div className="stats-wrapper">
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement={null}
              scoreCategory={null}
              scoreUnit={null}
              dateFilter={dateFilter}
              operation="sum"
              postType="Workout"
              
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement={null}
              scoreCategory={null}
              scoreUnit={null}
              dateFilter={dateFilter}
              operation="sum"
              postType="Sun"
              
            />
            
          </div>
          <h3 className="section-heading">Test Totals</h3>
          <h4 className="section-info">Totals of all your fitness tests</h4>
          <div className="stats-wrapper">
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Deadlift"
              scoreCategory={null}
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Power Clean"
              scoreCategory={null}
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
             <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Squat Clean"
              scoreCategory={null}
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
             <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Power Snatch"
              scoreCategory={null}
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
             <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Squat Snatch"
              scoreCategory={null}
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
             <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Back Squat"
              scoreCategory={null}
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
             <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Front Squat"
              scoreCategory={null}
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
             <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Shoulder Press"
              scoreCategory={null}
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Push Press"
              scoreCategory={null}
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="sum"
              postType={"Test"}
            />
             <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Bench Press"
              scoreCategory={null}
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
             <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Floor Press"
              scoreCategory={null}
              scoreUnit="Lbs"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
             <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Pull-ups"
              scoreCategory={null}
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Push-ups"
              scoreCategory={null}
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Strict Handstand Push-ups"
              scoreCategory={null}
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Burpees"
              scoreCategory={null}
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Bar Dips"
              scoreCategory={null}
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Ring Dips"
              scoreCategory={null}
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Ring Muscle-ups"
              scoreCategory={null}
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Bar Muscle-ups"
              scoreCategory={null}
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Double Unders"
              scoreCategory={null}
              scoreUnit="Reps"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Row"
              scoreCategory={null}
              scoreUnit="Meters"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Row"
              scoreCategory={null}
              scoreUnit="Calories"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Assault Bike"
              scoreCategory={null}
              scoreUnit="Meters"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Assault Bike"
              scoreCategory={null}
              scoreUnit="Calories"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Run"
              scoreCategory={null}
              scoreUnit="Meters"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
            <Stat
              className="stat"
              posts={posts}
              userId={userId}
              movement="Run"
              scoreCategory={null}
              scoreUnit="Minutes"
              dateFilter={dateFilter}
              operation="sum"
              postType="Test"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Stats;
