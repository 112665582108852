// src/relationships.js
const relationships = {
  Deadlift: {
    displayName: "Deadlift",
    categories: {
      "One Rep Max": ["Lbs"],
      "Ten Rep Max": ["Lbs"],
    },
  },
  PowerClean: {
    displayName: "Power Clean",
    categories: {
      "One Rep Max": ["Lbs"],
      "Ten Rep Max": ["Lbs"],
    },
  },
  SquatClean: {
    displayName: "Squat Clean",
    categories: {
      "One Rep Max": ["Lbs"],
      "Ten Rep Max": ["Lbs"],
    },
  },
  BackSquat: {
    displayName: "Back Squat",
    categories: {
      "One Rep Max": ["Lbs"],
      "Ten Rep Max": ["Lbs"],
      "Twenty Rep Max": ["Lbs"],
    },
  },
  FrontSquat: {
    displayName: "Front Squat",
    categories: {
      "One Rep Max": ["Lbs"],
      "Ten Rep Max": ["Lbs"],
      "Twenty Rep Max": ["Lbs"],
    },
  },
  StrictShoulderPress: {
    displayName: "Strict Shoulder Press",
    categories: {
      "One Rep Max": ["Lbs"],
      "Ten Rep Max": ["Lbs"],
      "Twenty Rep Max": ["Lbs"],
    },
  },
  PushPress: {
    displayName: "Push Press",
    categories: {
      "One Rep Max": ["Lbs"],
      "Ten Rep Max": ["Lbs"],
      "Twenty Rep Max": ["Lbs"],
    },
  },
  BenchPress: {
    displayName: "Bench Press",
    categories: {
      "One Rep Max": ["Lbs"],
      "Ten Rep Max": ["Lbs"],
      "Twenty Rep Max": ["Lbs"],
    },
  },
  FloorPress: {
    displayName: "Floor Press",
    categories: {
      "One Rep Max": ["Lbs"],
      "Ten Rep Max": ["Lbs"],
      "Twenty Rep Max": ["Lbs"],
    },
  },
  StrictPullUps: {
    displayName: "Strict Pull-ups",
    categories: {
      "Max Reps": ["Reps"],
      "One Rep Max": ["Lbs"],
      "Ten Rep Max": ["Lbs"],
      "Twenty Rep Max": ["Lbs"],
      "Thirty Second Test": ["Reps"],
      "One Minute Test": ["Reps"],
      "Ten Minute Test": ["Reps"],
    },
  },
  PushUps: {
    displayName: "Push-ups",
    categories: {
      "Max Reps": ["Reps"],
      "One Rep Max": ["Lbs"],
      "Ten Rep Max": ["Lbs"],
      "Twenty Rep Max": ["Lbs"],
      "Thirty Second Test": ["Reps"],
      "One Minute Test": ["Reps"],
      "Ten Minute Test": ["Reps"],
    },
  },
  StrictHandstandPushUps: {
    displayName: "Strict Handstand Push-ups",
    categories: {
      "Max Reps": ["Reps"],
      "Thirty Second Test": ["Reps"],
      "One Minute Test": ["Reps"],
      "Ten Minute Test": ["Reps"],
    },
  },
  Burpees: {
    displayName: "Burpees",
    categories: {
      "Thirty Second Test": ["Reps"],
      "One Minute Test": ["Reps"],
      "Ten Minute Test": ["Reps"],
      "Three Hundred Reps": ["Minutes"],
    },
  },
  BarDips: {
    displayName: "Bar Dips",
    categories: {
      "Max Reps": ["Reps"],
      "One Rep Max": ["Lbs"],
      "Ten Rep Max": ["Lbs"],
      "Thirty Second Test": ["Reps"],
      "One Minute Test": ["Reps"],
      "Ten Minute Test": ["Reps"],
    },
  },
    RingDips: {
      displayName: "Ring Dips",
      categories: {
        "Max Reps": ["Reps"],
        "One Rep Max": ["Lbs"],
        "Ten Rep Max": ["Lbs"],
        "Thirty Second Test": ["Reps"],
        "One Minute Test": ["Reps"],
        "Ten Minute Test": ["Reps"],
      },
    },
    RingMuscleUps: {
      displayName: "Ring Muscle-ups",
      categories: {
        "Max Reps": ["Reps"],
        "Thirty Second Test": ["Reps"],
        "One Minute Test": ["Reps"],
        "Ten Minute Test": ["Reps"],
      },
    },
    BarMuscleUps: {
      displayName: "Bar Muscle-ups",
      categories: {
        "Max Reps": ["Reps"],
        "Thirty Second Test": ["Reps"],
        "One Minute Test": ["Reps"],
        "Ten Minute Test": ["Reps"],
      },
    },
    DoubleUnders: {
      displayName: "Double Unders",
      categories: {
        "Max Reps": ["Reps"],
        "Thirty Second Test": ["Reps"],
        "One Minute Test": ["Reps"],
      },
    },
    Row: {
      displayName: "Row",
      categories: {
        "Thirty Second Test": ["Calories"],
        "One Minute Test": ["Calories"],
        "Ten Minute Test": ["Calories"],
        "Meters (400)": ["Minutes"],
        "Meters (800)": ["Minutes"],
        "One Mile": ["Minutes"],
      },
    },
    AssaultBike: {
      displayName: "Assault Bike",
      categories: {
        "Thirty Second Test": ["Calories"],
        "One Minute Test": ["Calories"],
        "Ten Minute Test": ["Calories"],
        "Meters (400)": ["Minutes"],
        "Meters (800)": ["Minutes"],
        "One Mile": ["Minutes"],
      },
    },
    Run: {
      displayName: "Run",
      categories: {
        "Meters (400)": ["Minutes"],
        "Meters (800)": ["Minutes"],
        "One Mile": ["Minutes"],
      },
    },
  };

  export default relationships;
