import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import MovementChart from './MovementChart';
import Metrics from './Metrics';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactMarkdown from 'react-markdown';
import defaultProfilePic from './img/placeholder.svg';
import { getUrlPrefix } from './utils/utils';
import { Helmet } from 'react-helmet';
import relationships from './relationships';
import Confetti from 'react-confetti';
import './SuccessModal.css';


const Profile = () => {
  const [userData, setUserData] = useState(null);
  const { username } = useParams();
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const [postType, setPostType] = useState('');
  const [postMovement, setPostMovement] = useState('');
  const [postDescription, setPostDescription] = useState('');
  const [postScoreCategory, setPostScoreCategory] = useState('');
  const [postScoreUnit, setPostScoreUnit] = useState('');
  const [postMinutes, setPostMinutes] = useState('');
  const [postScore, setPostScore] = useState('');
  const [postDate, setPostDate] = useState(() => {
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    // Adding 0 in front of numbers < 10
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;

    return `${year}-${month}-${day}`;
  });

  const [userPosts, setUserPosts] = useState([]);
  const [userId, setUserId] = useState(null); // Add this line to create a new state variable for the user ID
  const [page, setPage] = useState(1);
  const [allUserPosts, setAllUserPosts] = useState([]);
  const [showPostEntry, setShowPostEntry] = useState(false);
  const navigate = useNavigate();
  const [postsUpdated, setPostsUpdated] = useState(false);
  const [isLoadingPosts, setIsLoadingPosts] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [postMovementKey, setPostMovementKey] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const postEntryRef = useRef(null);
  const hideButtonRef = useRef(null);
  const [postEnergy, setPostEnergy] = useState('');
  const [postMood, setPostMood] = useState('');
  const [postSleep, setPostSleep] = useState('');
  const getMoodClass = (value) => {
    if (value >= 1 && value <= 3) return 'bad';
    if (value >= 4 && value <= 7) return 'meh';
    if (value >= 8 && value <= 10) return 'good';
    return '';
  };
  const successMessages = [
    "&#127881; Awesome!",
    "&#128296; Nailed it!",
    "&#128074; LET'S GO!!!",
    "&#127947; CRUSHING IT!",
    "&#65039; Like Jay-Z said: on to the next one!!!",
  ];
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [currentSuccessMessage, setCurrentSuccessMessage] = useState("");
  const [isFollowing, setIsFollowing] = useState(false);
  const setCurrentUser = (userData) => {
      localStorage.setItem('user', JSON.stringify(userData));
  }

  const [postPhoto, setPostPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];

    // Limit the size of the uploaded photo to 5MB
    if (file.size > 5 * 1024 * 1024) { // size in bytes
      alert('Your photo is too large. Please make sure it is under 5MB.');
      return;
    }

    setPostPhoto(file);

    // Create an object of FileReader
    const reader = new FileReader();

    // Read the selected file as Data URL
    reader.readAsDataURL(file);

    // set the image preview in state
    reader.onloadend = () => {
      setPhotoPreview(reader.result);
    };
  };






  useEffect(() => {
    const fetchUserData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users?_limit=1&_q=${username}&populate=profile_pic&populate=posts&populate=following&populate=followers`
      );
      const data = await response.json();

      

      if (data && data.length > 0) {
        const fetchedUsername = data[0].username;

        // If the fetched username doesn't match the current user's username, redirect to a fallback page
        if (fetchedUsername !== username) {
          navigate('/user-not-found');
        } else {
          setUserData(data[0]);
          setUserId(data[0].id);
          fetchUserPosts(data[0].id);
        }
      } else {
        setUserData(null);
        navigate('/user-not-found');
      }
    };

    fetchUserData();
  }, [username]);


  const fetchMorePosts = () => {
    setPage((prevPage) => prevPage + 1);
    fetchUserPosts(userId, page + 1);
  };

  const normalizeDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0);
    return normalizedDate;
  };

  const fetchUserPosts = async (userId, page = 1, pageSize = 10) => {
    const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${userId}?populate=posts,posts.photo`);
    const user = userResponse.data;

    const sortedPosts = user.posts.sort((a, b) => new Date(b.date) - new Date(a.date));

    // Set allUserPosts with the sorted posts
    setAllUserPosts(sortedPosts);

    const paginatedPosts = sortedPosts.slice(0, page * pageSize);

    // Group posts by date
    const groupedPosts = paginatedPosts.reduce((acc, post) => {
      const postDate = new Date(post.date).toLocaleDateString('en-US', { timeZone: 'UTC' });
      if (!acc[postDate]) {
        acc[postDate] = [];
      }
      acc[postDate].push(post);
      return acc;
    }, {});

    setUserPosts(groupedPosts);
    setIsLoadingPosts(false); 

    // If the total number of posts is less than or equal to the number of posts already displayed, 
    // set hasMorePosts to false
    if (sortedPosts.length <= paginatedPosts.length) {
      setHasMorePosts(false);
    }
};

  const handleDeletePost = async (postId) => {
    if (currentUser && currentUser.user.username === username) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/posts/${postId}`, {
          headers: {
            Authorization: `Bearer ${currentUser.jwt}`,
          },
        });

        setUserPosts((prevPosts) => {
          const updatedPosts = { ...prevPosts };
          const postDate = Object.keys(updatedPosts).find((date) =>
            updatedPosts[date].some((post) => post.id === postId)
          );

          if (postDate) {
            const filteredPosts = updatedPosts[postDate].filter(
              (post) => post.id !== postId
            );

            if (filteredPosts.length > 0) {
              updatedPosts[postDate] = filteredPosts;
            } else {
              delete updatedPosts[postDate];
            }
          }

          return updatedPosts;
        });
      } catch (error) {
        if (error.response) {
          console.error('Error response:', error.response);
        } else {
          console.error('Error:', error);
        }
      }
    }
  };

  const handlePostSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (currentUser && currentUser.user.username === username) {
      let photoId;
      if (postPhoto && postType === 'Workout') {
        const formData = new FormData();
        formData.append('files', postPhoto);

        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${currentUser.jwt}`, // Add the JWT token to the Authorization header
            },
          });

          // Assign the uploaded file's ID
          photoId = response.data[0].id;
        } catch (error) {
          console.error('Error:', error);
          return; // Stop execution if the photo upload fails
        }
      }

      const formData = new FormData(event.target);
      let data = Object.fromEntries(formData.entries()); 

      // Remove the empty fields
      if (!data.mood) delete data.mood;
      if (!data.energy) delete data.energy;
      if (!data.hours_of_sleep) delete data.hours_of_sleep;

      const movementKey = Object.keys(relationships).find(
        (key) => relationships[key].displayName === data.movement
      );

      let score_unit;
      if (data.type === 'Test') {
        if (relationships[postMovementKey]) {
          score_unit = relationships[postMovementKey].categories[data.score_category][0];
        } else {
          console.error('Movement key not found:', postMovementKey);
          return; // Stop execution if the movement key is not found
        }
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/posts`, {
          data: {
            ...data,
            user: currentUser.user.id,
            ...(score_unit && { score_unit }), // Include the score_unit in the data object if it's defined
            ...(photoId && { photo: photoId }), // Include the photo id in the data object if it's defined
          },
        }, {
          headers: {
            Authorization: `Bearer ${currentUser.jwt}`,
          },
        });

        const postDateFormatted = new Date(data.date).toLocaleDateString('en-US', { timeZone: 'UTC' });

        const newPost = {
          id: response.data.id,
          type: data.type,
          description: data.description,
          minutes: data.minutes,
          date: data.date,
          movement: data.movement,
          score: data.score,
          score_category: data.score_category,
          unit: data.score_unit,
          ...(photoId && { photo: photoId }),
        };

        setUserPosts((prevPosts) => {
          const updatedPosts = { ...prevPosts };

          if (updatedPosts[postDateFormatted]) {
            updatedPosts[postDateFormatted] = [newPost, ...updatedPosts[postDateFormatted]];
          } else {
            updatedPosts[postDateFormatted] = [newPost];
          }

          const updatedUserData = { ...userData };
          updatedUserData.posts = [...updatedUserData.posts, newPost];

          setUserData(updatedUserData);

          return updatedPosts;
        });

        event.target.reset();

        setPostType('');
        setPostDescription('');
        setPostMinutes('');
        setPostMovement('');
        setPostScoreCategory('');
        setPostScoreUnit('');
        setPostScore('');
        setPostDate('');

        setShowSuccessModal(true);
        const randomIndex = Math.floor(Math.random() * successMessages.length);
        setCurrentSuccessMessage(successMessages[randomIndex]);

        setTimeout(() => {
          setShowSuccessModal(false);
          window.location.reload(); // Reloads the page after hiding the success modal
        }, 3000);


      } catch (error) {
        setIsLoading(false); 
        if (error.response) {
          console.error('Error response:', error.response);
        } else {
          console.error('Error:', error);
        }
      }
      setIsLoading(false);
    }
  };


  const formatDateWithDay = (dateString) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(dateString);
    const day = days[date.getDay()];
    const formattedDate = `${day}, ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  };

  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 100,
    dragFriction: 0.1,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  useEffect(() => {
    if (currentUser && currentUser.user && currentUser.user.following && userData && currentUser.user.following.includes(userData.id)) {
      setIsFollowing(true);
    }
  }, [currentUser, userData]);



  const followUser = async () => {
    if (userData && currentUser) {
      if (!currentUser.user.id) {
        console.error('currentUser.id is not defined');
        return;
      }
      try {
        const currentFollowing = Array.isArray(currentUser.user.following) ? currentUser.user.following : [];
        const updatedFollowing = [...currentFollowing, userData.id];
        
        await axios.put(`${process.env.REACT_APP_API_URL}/api/users/${currentUser.user.id}`, {
          following: updatedFollowing,
        }, {
          headers: {
            Authorization: `Bearer ${currentUser.jwt}`,
          },
        });

        const currentFollowers = Array.isArray(userData.followers) ? userData.followers : [];
        const updatedFollowers = [...currentFollowers, currentUser.user.id];
        
        await axios.put(`${process.env.REACT_APP_API_URL}/api/users/${userData.id}`, {
          followers: updatedFollowers,
        }, {
          headers: {
            Authorization: `Bearer ${currentUser.jwt}`,
          },
        }).catch(error => {
          console.error('Failed to update user followers: ', error);
        });

        // After updating the database, update currentUser and userData in your app
        const updatedCurrentUser = { ...currentUser };
        updatedCurrentUser.user.following = updatedFollowing;
        setCurrentUser(updatedCurrentUser);

        const updatedUserData = { ...userData };
        updatedUserData.followers = updatedFollowers;
        setUserData(updatedUserData);

        setIsFollowing(true);

      } catch (error) {
        console.error(error);
      }
    } else {
      console.error('Either currentUser or userData is not defined');
    }
  };

  const unfollowUser = async () => {
    if (userData && currentUser) {
      if (!currentUser.user.id) {
        console.error('currentUser.id is not defined');
        return;
      }
      try {
        const currentFollowing = Array.isArray(currentUser.user.following) ? currentUser.user.following : [];
        const updatedFollowing = currentFollowing.filter(id => id !== userData.id);
        
        await axios.put(`${process.env.REACT_APP_API_URL}/api/users/${currentUser.user.id}`, {
          following: updatedFollowing,
        }, {
          headers: {
            Authorization: `Bearer ${currentUser.jwt}`,
          },
        });

        const currentFollowers = Array.isArray(userData.followers) ? userData.followers : [];
        const updatedFollowers = currentFollowers.filter(id => id !== currentUser.user.id);
        
        await axios.put(`${process.env.REACT_APP_API_URL}/api/users/${userData.id}`, {
          followers: updatedFollowers,
        }, {
          headers: {
            Authorization: `Bearer ${currentUser.jwt}`,
          },
        }).catch(error => {
          console.error('Failed to update user followers: ', error);
        });

        // After updating the database, update currentUser and userData in your app
        const updatedCurrentUser = { ...currentUser };
        updatedCurrentUser.user.following = updatedFollowing;
        setCurrentUser(updatedCurrentUser);

        const updatedUserData = { ...userData };
        updatedUserData.followers = updatedFollowers;
        setUserData(updatedUserData);

        setIsFollowing(false);

      } catch (error) {
        console.error(error);
      }
    } else {
      console.error('Either currentUser or userData is not defined');
    }
  };

  const handleClickOutside = event => {
    if (postEntryRef.current && !postEntryRef.current.contains(event.target) && hideButtonRef.current !== event.target) {
      setShowPostEntry(false);
    }
  };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

  return (
    <div className="stage">
      {userData ? (
        <>
          <Helmet>
            <title>{userData.username}'s fitness metrics on Fitpholio</title>
            <meta name="description" content={`${userData.username} tracks workouts, metrics, and tests on Fitpholio.`} />
            <meta property="og:title" content={`${userData.username}'s fitness metrics on Fitpholio`} />
            <meta property="og:description" content={`${userData.username} tracks workouts, metrics, and tests on Fitpholio.`} />
            <meta property="og:image" content="https://vacuous-miniature-wilderness.media.strapiapp.com/fitpholio_social_share_3346b17feb.png?updated_at=2023-05-02T22:30:53.554Z" />
          </Helmet>
          <div className="profile-header">
            <div className="profile-links">
              <Link className="inactive" to={`/${userData.username}/stats`}>Stats</Link>
              <Link className="inactive" to={`/${userData.username}/notes`}>Notes</Link>
              <Link className="inactive" to={`/${userData.username}/fitness-tests`}>Tests</Link>
              <Link className="active" to={`/${userData.username}`}>Timeline</Link>  
            </div>
            <>
              <div>
                <Link to={`/${userData.username}`}>
                  <img
                    className="profile-pic"
                    src={
                      userData.profile_pic
                        ? `${getUrlPrefix()}${userData.profile_pic.url}`
                        : defaultProfilePic
                    }
                    alt={`${userData.first_name} ${userData.last_name}`}
                  />
                </Link>
              </div>
              <h1 className="big-name">
                {userData.first_name && userData.last_name
                  ? `${userData.first_name} ${userData.last_name}`
                  : userData.username}
              </h1>
              {userData.first_name && userData.last_name ? (
                <p className="small-name">@{userData.username}</p>
              ) : null}
              <div className="following-wrapper">
                <Link to={`/${userData.username}/following`}>Following {userData?.following?.length ?? 0}</Link>
                <Link to={`/${userData.username}/followers`}>Followers {userData?.followers?.length ?? 0}</Link>
              </div>

              <h2 className="bio"><ReactMarkdown>{userData.bio}</ReactMarkdown></h2>
              {currentUser && currentUser.user.username !== username && (
                <button 
                  onClick={isFollowing ? unfollowUser : followUser}
                  className={isFollowing ? 'class-for-unfollow' : 'class-for-follow'}
                >
                  {isFollowing ? 'Unfollow' : 'Follow'}
                </button>
              )}
            </>

          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
      {currentUser && currentUser.user.username === username && (
        <>
          <button
            ref={hideButtonRef}
            className={`post-toggle ${showPostEntry ? "post-hide" : "post-show"}`}
            onClick={() => setShowPostEntry(!showPostEntry)}
          >
            {showPostEntry ? "Hide" : "Post"}
          </button>

          {showPostEntry && (
            <div className="post-entry" ref={postEntryRef}>
              <button
                className={`post-toggle ${showPostEntry ? "post-hide-x" : "post-show-x"}`}
                onClick={() => setShowPostEntry(!showPostEntry)}
              >
                {showPostEntry ? '✖' : '✖'}
              </button>

              <form onSubmit={handlePostSubmit}>
                <label htmlFor="type">Type:</label>
                <select
                  id="type"
                  name="type"
                  value={postType}
                  onChange={(e) => setPostType(e.target.value)}
                  required
                >
                  <option value="">Select a type</option>
                  <option value="Workout">Workout</option>
                  <option value="Sun">Sun</option>
                  <option value="Sauna">Sauna</option>
                  <option value="Cold">Cold</option>
                  <option value="Test">Test</option>
                  <option value="Note">Note</option>
                  <option value="Checkin">Checkin</option>
                </select>

                {(postType === 'Workout' || postType === 'Note') && (
                  <div>
                    <label htmlFor="description">Description <span className="label-more">(<a className="markdown-link" href="https://daringfireball.net/projects/markdown/syntax" target="_blank">markdown</a> enabled)</span></label>
                    <textarea
                      id="description"
                      name="description"
                      value={postDescription}
                      onChange={(e) => setPostDescription(e.target.value)}
                    />
                  </div>
                )}

                {(postType === 'Workout' ||
                  postType === 'Sun' ||
                  postType === 'Sauna' ||
                  postType === 'Cold') && (
                  <div>
                    <label htmlFor="minutes">Minutes:</label>
                    <input
                      type="number"
                      id="minutes"
                      name="minutes"
                      value={postMinutes}
                      onChange={(e) => setPostMinutes(e.target.value)}
                      required
                    />
                  </div>
                )}

                {postType === 'Workout' && (
                  <div>
                    <label htmlFor="photo" className="custom-file-upload">
                      📷 Add Photo
                      <input
                        className="post-photo"
                        type="file"
                        id="photo"
                        name="photo"
                        onChange={handlePhotoChange}
                        style={{display: "none"}} // Hide the default file input
                      />
                    </label>
                    {photoPreview && (
                      <img className="post-photo-preview" src={photoPreview} alt="Selected" style={{ height: '200px' }} />
                    )}
                  </div>
                )}

                {postType === 'Checkin' && (
                  <div>
                    <label htmlFor="energy">Energy Rating:</label>
                    <input
                      type="number"
                      id="energy"
                      name="energy"
                      value={postEnergy}
                      onChange={(e) => setPostEnergy(e.target.value)}
                      placeholder="Enter your energy level ( 1 is low and 10 is high)"
                    />

                    <label htmlFor="mood">Mood Rating:</label>
                    <input
                      type="text"
                      id="mood"
                      name="mood"
                      value={postMood}
                      onChange={(e) => setPostMood(e.target.value)}
                      placeholder="Enter your mood ( 1 is bad and 10 is good)"
                    />

                    <label htmlFor="sleep">Hours of Sleep:</label>
                    <input
                      type="number"
                      id="hours_of_sleep"
                      name="hours_of_sleep"
                      value={postSleep}
                      onChange={(e) => setPostSleep(e.target.value)}
                      placeholder="Enter your hours of sleep last night"
                    />
                  </div>
                )}


                {postType === 'Test' && (
                  <div>
                    <label htmlFor="movement">Movement:</label>
                    <select
                      id="movement"
                      name="movement"
                      value={postMovement}
                      onChange={(e) => {
                        const key = e.target.options[e.target.selectedIndex].dataset.key;
                        setPostMovementKey(key);
                        setPostMovement(e.target.value);

                        // Check if the selected score_category is available for the new movement
                        if (key) {
                          const movement = relationships[key];
                          if (movement && !movement.categories[postScoreCategory]) {
                            // Reset the postScoreCategory state if the category is not available
                            setPostScoreCategory('');
                          }
                        } else {
                          // If no movement is selected, reset the postScoreCategory state
                          setPostScoreCategory('');
                        }
                      }}
                      required
                    >
                      <option value="">Select a Movement</option>
                      {Object.entries(relationships).map(([key, { displayName }]) => (
                        <option value={displayName} data-key={key} key={key}>
                          {displayName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                  {postType === 'Test' && postMovementKey && (
                    <>
                      <div>
                        <label htmlFor="score_category">Score Category:</label>
                        <select
                          id="score_category"
                          name="score_category"
                          value={postScoreCategory}
                          onChange={(e) => setPostScoreCategory(e.target.value)}
                          required
                        >
                          <option value="">Select a category</option>
                          {Object.keys(relationships[postMovementKey].categories).map((category) => (
                            <option key={category} value={category}>
                              {category}
                            </option>
                          ))}
                        </select>
                      </div>

                      {postScoreCategory && (
                        <div>
                          <label htmlFor="score">
                            {relationships[postMovementKey].categories[postScoreCategory][0]}:
                          </label>
                          <input
                            type="number"
                            id="score"
                            name="score"
                            value={postScore}
                            onChange={(e) => setPostScore(e.target.value)}
                            required
                          />
                        </div>
                      )}
                    </>
                  )}

                  {postType && (
                    <div>
                      <label htmlFor="date">Date:</label>
                      <input
                        type="date"
                        id="date"
                        name="date"
                        value={postDate}
                        onChange={(e) => setPostDate(e.target.value)}
                        required
                      />
                    </div>
                  )}

                  <button className="btn-primary" type="submit">Post</button>
              </form>
            </div>
          )}
        </>
      )}
      <Metrics userData={userData} postsUpdated={postsUpdated} />

      {isLoading && (
        <div className="loading-overlay">
          <p>Posting...</p>
        </div>
      )}

      {isLoadingPosts ? (
        <h4>Loading...</h4>
      ) : Object.keys(userPosts).length > 0 ? (
        <InfiniteScroll
          style={{ overflow: 'visible' }}
          dataLength={Object.keys(userPosts).length}
          next={fetchMorePosts}
          hasMore={hasMorePosts} // You can change this based on a condition when there are no more posts to load
          loader={<h4>Loading...</h4>}
          endMessage={
            <p className="no-more-posts">&#127881; Yay! You have seen it all. No more posts are available.</p>
          }
        >
          <h2 className="timeline-title">Timeline</h2>
          {Object.entries(userPosts).map(([date, posts]) => (
            <div key={date}>
              <h3 className="timeline-date">{formatDateWithDay(date)}</h3>
              {posts.map((post, index) => (
                <div 
                  key={`${date}_${post.id}`}  // combining date and post id to create a unique key
                  className={`${post.type} ${post.photo && post.type === 'Workout' ? 'workout-with-photo' : ''}`}
                  style={post.photo && post.type === 'Workout' ? {backgroundImage: `url(${getUrlPrefix()}${post.photo.url})`, backgroundSize: 'cover', backgroundPosition: 'center', position: 'relative'} : {}}
                  onClick={() => navigate(`/${username}/posts/${post.id}`)}
                >
                  <div style={{position: 'relative', zIndex: 2}}>
                    <div className="post-icon"></div>
                    <div className="post-content">
                      {currentUser && currentUser.user.username === username && (
                        <button
                          className="delete-post"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents navigating to the post when the button is clicked
                            handleDeletePost(post.id);
                          }}
                        >
                          Delete
                        </button>
                      )}
                      <h4 className="post-type">{post.type}</h4>
                      <p className="post-date">{post.date}</p>
                      {post.type === 'Checkin' ? (
                        <>
                          {post.mood && <p className={`post-mood ${getMoodClass(post.mood)}`}>Mood: {post.mood}</p>}
                          {post.energy && <p className={`post-energy ${getMoodClass(post.energy)}`}>Energy: {post.energy}</p>}
                          {post.hours_of_sleep && <p className={`post-sleep ${getMoodClass(post.hours_of_sleep)}`}>Hours of Sleep: {post.hours_of_sleep}</p>}
                        </>
                      ) : (
                        <>
                          <ReactMarkdown className="post-description">{post.description}</ReactMarkdown>
                          <p className="post-minutes">{post.minutes} minutes</p>
                        </>
                      )}
                      {post.type === 'Test' && (
                        <div>
                          <h4 className="post-movement">{post.movement}</h4>
                          <MovementChart
                            posts={allUserPosts}
                            movement={post.movement}
                            postDate={post.date}
                            score_category={post.score_category}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
          </InfiniteScroll>
          ) : (
            <div className="no-posts">
              <p>Posts will display once there are posts.</p>
              <Link to={"/bgadoci"}>Follow @bgadoci to get started</Link>
            </div>
      )}
      {showSuccessModal && (
        <>
          {/* Confetti effect */}
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
              zIndex: 1000,
            }}
          >
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              numberOfPieces={200}
              recycle={false}
            />
          </div>

          {/* Success message overlay */}
          <div 
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgb(2, 6, 23, .6",
              color: "white",
              padding: "20px 40px",
              borderRadius: "10px",
              zIndex: 1001,
              fontSize: "2rem",
              fontWeight: "600",
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: currentSuccessMessage }} />
          </div>
        </>
      )}

    </div>
  );
};

export default Profile;
