import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import defaultProfilePic from './img/placeholder.svg';
import { getUrlPrefix } from './utils/utils';
import axios from 'axios';

const Followers = () => {
  const { username } = useParams();
  const [user, setUser] = useState(null);
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const setCurrentUser = (userData) => {
      localStorage.setItem('user', JSON.stringify(userData));
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/users?username=${username}`)
      .then((response) => response.json())
      .then((data) => {
        const exactMatchUser = data.find(user => user.username === username);

        if (exactMatchUser) {
          const userId = exactMatchUser.id;

          fetch(`${process.env.REACT_APP_API_URL}/api/users/${userId}?populate[followers][populate][0]=posts&populate[followers][populate][1]=profile_pic`)
            .then((response) => response.json())
            .then((userData) => {
              setUser(userData);
            })
            .catch((error) => console.error(error));
        } else {
          console.error('User not found');
        }
      })
      .catch((error) => console.error(error));
  }, [username]);

  if (!user) {
    return <div>Loading...</div>;
  }


  return (
    <div className="stage following">

          <h1>
              <Link to={`/${username}`}>
                {username}
              </Link>
              <span> followers</span>
          </h1>
          {user.followers.map((followersUser) => (
            <div className="following-user" key={followersUser.id}>
              <Link to={`/${followersUser.username}`}>
                <div className="left-items">
                  <img
                    className="profile-pic"
                    src={
                      followersUser.profile_pic
                        ? `${getUrlPrefix()}${followersUser.profile_pic.formats.thumbnail.url}`
                        : defaultProfilePic
                    }
                    alt={`${followersUser.first_name} ${followersUser.last_name}`}
                  />
                  <div className="username">
                    <h2>{followersUser.username}</h2>
                    <p className="bio">{followersUser.bio}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
  );
};

export default Followers;
