import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavbarContext from './NavbarContext';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const { updateNavbar } = useContext(NavbarContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!/^[a-z0-9]+$/i.test(username)) {
      setError('Usernames must be lowercase and cannot contain spaces.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/local/register`, {
        username: username.toLowerCase(),
        email,
        password,
      });
      localStorage.setItem('user', JSON.stringify(response.data));
      updateNavbar();
      navigate(`/${username}`);
    } catch (error) {
      setError('An error occurred during registration. Please try again.');
    }
  };

  return (
    <div className="stage">
      <h1>Let's go!</h1>
      {error && <p>{error}</p>}
      <form onSubmit={handleSubmit}>
        <h2>Create your account and get tracking</h2>
        <label>Username</label>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          required
        />
        <label>Email</label>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />
        <label>Password</label>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          required
        />
        <button className="btn-primary" type="submit">Register</button>
      </form>
    </div>
  );
};

export default Register;
