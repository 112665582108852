import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios'; 
import ReactMarkdown from 'react-markdown';
import MovementChart from './MovementChart';
import { getUrlPrefix } from './utils/utils';
import defaultProfilePic from './img/placeholder.svg';

function Post() {
  const { username, id } = useParams();
  const [post, setPost] = useState(null);
  const [allUserPosts, setAllUserPosts] = useState([]);
  const getMoodClass = (value) => {
    if (value >= 1 && value <= 3) return 'bad';
    if (value >= 4 && value <= 7) return 'meh';
    if (value >= 8 && value <= 10) return 'good';
    return '';
  };

  useEffect(() => {
    const fetchPostAndUserPosts = async () => {
      const responsePost = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/posts/${id}?populate=user,photo,user.profile_pic`
      );
      setPost(responsePost.data.data.attributes);

      const responseUserPosts = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/${responsePost.data.data.attributes.user.data.id}?populate=posts,posts.photo`
      );
      setAllUserPosts(responseUserPosts.data.posts);

    };

    fetchPostAndUserPosts();
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  const postUsername = post.user.data.attributes.username;

  return (
    <div className="post-show-page">
      <div className={`${post.type} stage post ${post.photo && post.photo.data && post.photo.data.attributes && post.photo.data.attributes.formats && post.photo.data.attributes.formats.large && post.type === 'Workout' ? 'workout-with-photo' : ''}`}


        style={
          post.photo && 
          post.photo.data &&
          post.photo.data.attributes &&
          post.type === 'Workout' 
          ? {
            backgroundImage: post.photo && post.photo.data && post.photo.data.attributes && post.photo.data.attributes.formats && post.photo.data.attributes.formats.large && post.photo.data.attributes.formats.large.url 
              ? `url(${getUrlPrefix()}${post.photo.data.attributes.formats.large.url})` 
              : undefined,

            backgroundSize: 'cover', 
            backgroundPosition: 'center', 
            position: 'relative'
          } 
          : {}
        }
      >
        <div style={{position: 'relative', zIndex: 2}}>
          <div className="post-icon"></div>
          <div className="post-content">
            <h4 className="post-type">{post.type}</h4>
            <p className="post-date">{post.date}</p>
            <ReactMarkdown className="post-description">{post.description}</ReactMarkdown>
            <p className="post-minutes">{post.minutes} minutes</p>
            {post.type === 'Checkin' ? (
              <>
                <p className={`post-mood ${getMoodClass(post.mood)}`}>Mood: {post.mood}</p>
                <p className={`post-energy ${getMoodClass(post.energy)}`}>Energy: {post.energy}</p>
                <p className={`post-sleep ${getMoodClass(post.hours_of_sleep)}`}>Hours of Sleep: {post.hours_of_sleep}</p>
              </>
            ) : (
              <>
                <ReactMarkdown className="post-description">{post.description}</ReactMarkdown>
                <p className="post-minutes">{post.minutes} minutes</p>
              </>
            )}
            {post.type === 'Test' && (
              <div>
                <h4 className="post-movement">{post.movement}</h4>
                <MovementChart
                  posts={allUserPosts}
                  movement={post.movement}
                  postDate={post.date}
                  score_category={post.score_category}
                />
              </div>
            )}
            <Link className="by-user" to={`/${postUsername}`}>
              <img 
                src={
                  post.user.data.attributes.profile_pic?.data?.attributes?.url
                    ? `${getUrlPrefix()}${post.user.data.attributes.profile_pic.data.attributes.url}`
                    : defaultProfilePic
                }
                alt={`${postUsername}'s profile picture`} 
              />



              <p>{postUsername}</p>
            </Link>


          </div>
        </div>
      </div>
    </div>
  );
}

export default Post;
