import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const [userDataForChart, setUserDataForChart] = useState([]);
  const [postDataForChart, setPostDataForChart] = useState([]);
  const [recentUsers, setRecentUsers] = useState([]);
  const [recentPosts, setRecentPosts] = useState([]);


  useEffect(() => {

    const aggregateDataByDate = (data, dateField) => {
      return data.reduce((acc, cur) => {
        const dateValue = dateField.split('.').reduce((o,i)=>o[i], cur);
        if (dateValue) {
          const dateObject = new Date(dateValue);
          if (!isNaN(dateObject)) {
            const date = dateObject.toISOString().split('T')[0];
            const found = acc.find((element) => element.date === date);
            if (found) {
              found.count += 1;
            } else {
              acc.push({
                date,
                count: 1,
              });
            }
          }
        }
        return acc;
      }, []);
    };

    const fetchData = async () => {
      const usersRes = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`);
      const postsRes = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts?populate=user`);

      const sortedUsersData = usersRes.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      // Sorting posts by post.date in descending order
      const sortedPostsData = postsRes.data.data.sort((a, b) => new Date(b.attributes.date) - new Date(a.attributes.date));

      // Get the 10 most recent users
      const recentUsers = sortedUsersData.slice(0, 10);

      // Get the 20 most recent posts
      const recentPosts = sortedPostsData.slice(0, 20);

      const userDataForChart = aggregateDataByDate(sortedUsersData, 'createdAt');
      const postDataForChart = aggregateDataByDate(postsRes.data.data, 'attributes.createdAt'); // using original posts data for charts

      // Sorting chart data in ascending order
      const sortedUserDataForChart = userDataForChart.sort((a, b) => new Date(a.date) - new Date(b.date));
      const sortedPostDataForChart = postDataForChart.sort((a, b) => new Date(a.date) - new Date(b.date));

      setRecentUsers(recentUsers);
      setRecentPosts(recentPosts);

      setUserDataForChart(sortedUserDataForChart);
      setPostDataForChart(sortedPostDataForChart);
    };

    fetchData();





    
    fetchData();
  }, []);

  useEffect(() => {
    const fetchRecentUsers = async () => {
      const usersRes = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`);

      // Sorting users by createdAt date in descending order
      const sortedUsers = usersRes.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      // Limiting the result to the 10 most recent users
      const recentUsers = sortedUsers.slice(0, 10);

      setRecentUsers(recentUsers);
    };

    fetchRecentUsers();
  }, []);

  return (
    <div className="stage dashboard">
      <h1>Admin Dashboard</h1>
      <div className="chart">
        <h2>User Data</h2>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={userDataForChart}>
            <Line type="monotone" dataKey="count" stroke="#34d399" />
            <CartesianGrid stroke="transparent" />
            <XAxis dataKey="date" stroke="#64748b" tick={{ fill: '#64748b' }} />
            <YAxis stroke="#64748b" tick={{ fill: '#64748b' }} />
            <Tooltip contentStyle={{ 
              color: '#64748b', 
              backgroundColor: '#0f172a',
              borderRadius: '10px',
              borderColor: '#34d399',
              boxShadow: '0px 0px 20px rgb(52, 211, 153, .2)',
              padding: '10px 15px'
              }} />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="chart">
        <h2>Post Data</h2>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={postDataForChart}>
            <Line type="monotone" dataKey="count" stroke="#34d399" />
            <CartesianGrid stroke="transparent" />
            <XAxis dataKey="date" stroke="#64748b" tick={{ fill: '#64748b' }} />
            <YAxis stroke="#64748b" tick={{ fill: '#64748b' }} />
            <Tooltip contentStyle={{ 
              color: '#64748b', 
              backgroundColor: '#0f172a',
              borderRadius: '10px',
              borderColor: '#34d399',
              boxShadow: '0px 0px 20px rgb(52, 211, 153, .2)',
              padding: '10px 15px'
              }} />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <h2>Recent Users</h2>
      <ul>
        {recentUsers.map(user => (
          <li key={user.id}>
            <Link to={`/${user.username}`}>
              {user.username}
            </Link>
            - {new Date(user.createdAt).toLocaleDateString()}
          </li>
        ))}
      </ul>
      <h2>Recent Posts</h2>
      <ul>
       {
         recentPosts
           .filter(post => post.attributes.user && post.attributes.user.data)  // Add this line
           .map((post) => (
             <li key={post.id}>
              <Link to={`/${post.attributes.user.data.attributes.username}`}>
                {post.attributes.type} - 
                {post.attributes.user.data ? post.attributes.user.data.attributes.username : 'Unknown'}
                - Created at: {new Date(post.attributes.createdAt).toLocaleDateString()}
              </Link>
             </li>
         ))
       }
      </ul>
    </div>
  );
}

export default Dashboard;
