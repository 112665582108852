import React, { useState } from 'react';
import CountUp from 'react-countup';

const Metrics = ({ userData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeView, setActiveView] = useState('totals'); // State to manage the active view

  if (userData && isLoading) {
    setIsLoading(false);
  }

  if (isLoading) {
    return <p>Loading metrics...</p>;
  }

  const posts = userData.posts;

  if (!posts || posts.length === 0) {
    return (
      <div className="no-metrics">
        <p>Metrics will display once there are posts.</p>
      </div>
    );
  }

  const lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);

  const filteredPostsByDate = posts.filter((post) => new Date(post.date) >= lastWeek);

  const calculateTotalMinutes = (type, useAllData = false) => {
    const filteredPosts = (useAllData ? posts : filteredPostsByDate).filter(
      (post) => post.type === type
    );

    const totalMinutes = filteredPosts.reduce(
      (total, post) => total + Number(post.minutes),
      0
    );

    return totalMinutes;
  };

  const calculateSevenDayAverage = (type) => {
    const totalMinutes = calculateTotalMinutes(type, true);
    const uniqueDays = new Set(posts.map((post) => post.date.split('T')[0])).size;
    const average = totalMinutes / Math.min(uniqueDays, 7);
    return Math.round(average);
  };

  const calculateAllTimeTotal = (type) => {
    return calculateTotalMinutes(type, true);
  };

  return (
    <>
      <div className="movement-chart">
        <h2>Minutes</h2>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setActiveView('totals');
          }}
          className={activeView === 'totals' ? 'active' : ''}
        >
          Trailing 7 days
        </a>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setActiveView('averages');
          }}
          className={activeView === 'averages' ? 'active' : ''}
        >
          Average 7 days
        </a>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setActiveView('allTimeTotal');
          }}
          className={activeView === 'allTimeTotal' ? 'active' : ''}
        >
          All time total
        </a>
      </div>
      <div className="metrics">
        {['Workout', 'Sun', 'Sauna', 'Cold'].map((type) => (
          <div key={type}>
            <span className={`metric-number ${type.toLowerCase()}-metric`}>
              <CountUp
                start={0}
                end={
                  activeView === 'averages'
                    ? calculateSevenDayAverage(type)
                    : activeView === 'allTimeTotal'
                    ? calculateAllTimeTotal(type)
                    : calculateTotalMinutes(type)
                }
                duration={1}
                separator=","
              />
            </span>
            <span className={`metric-label ${type.toLowerCase()}-metric-label`}>{type}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default Metrics;